import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import { StyledGrid } from "@opr-finance/component-grid";
import { StyledPageTitle } from "@opr-finance/component-content";
import { Image } from "@opr-finance/component-image";
import { FrontPageStyles } from "@opr-finance/theme-flex-online";
import { add, remove, sendDataLayerEvent, sendGAEvent } from "@opr-finance/utils";
import { Scroll } from "@opr-finance/component-scroll";
import { Font } from "@opr-finance/component-fonts";
import { Link } from "@opr-finance/component-link-to";
import { E_AllowedAccountStates } from "@opr-finance/feature-account";

import { selectOverdueDays, selectUnpaidAmount, selectNotPaidStatements } from "../../selectors";
import { FrontPageProps } from "./types";
import currencyImage from "../../images/OPR-Foretagslan-Flex-ut.svg";
import { messages } from "./messages";
import { AppState, E_Routes } from "../../types/general";
import { AccountDetailBlock } from "../../components/AccountDetailBlock/AccountDetailBlock";
import { NewsBlock } from "../../components/NewsBlock/NewsBlock";
import { WithdrawBlock } from "../../components/WithdrawBlock/WithdrawBlock";
import { CollectionBlock } from "../../components/CollectionBlock";
import { smeDocumentActions } from "@opr-finance/feature-document";
import { onComponentMounted } from "../../utils/onComponentMounted";

type LocationState = {
    component: string;
};

export function FrontPage(props: FrontPageProps) {
    const dispatch = useDispatch();
    const location = useLocation<LocationState>();
    const { formatMessage: fm } = useIntl();

    const component = location.state && location.state.component;

    const documentInUse = process.env.REACT_APP_DISPLAY_DOCUMENTS === "1" ? true : false;
    const authenticated = useSelector((state: AppState) => state.session.authenticated);
    const accountState = useSelector((state: AppState) => state.account.accountState);
    const { defaultPromissoryNoteId, activeAccountId, token, gwUrl, mockApiCalls } = useSelector(
        (state: AppState) => state.document.config
    );

    const account = useSelector((state: AppState) => {
        return state.account.account;
    });
    const boardMemberId = useSelector((state: AppState) => {
        return state.customer?.companyInfo?.boardmembers
            ? state.customer?.companyInfo?.boardmembers[0].id
            : "";
    });

    const notPaid = useSelector(selectNotPaidStatements);

    const overdueDays = useSelector(selectOverdueDays);

    const unpaidAmount = useSelector(selectUnpaidAmount);

    const [isWithdrawn, setIsWithdrawn] = useState(false);
    const [applicationData, setApplicationData] = useState<{ withdrawnAmount: string }>({
        withdrawnAmount: "0",
    });

    const [validForms, setValidForms] = useState<string[]>(() => {
        const defaultValids: string[] = ["withdrawnForm"];
        return defaultValids;
    });

    useEffect(() => {
        onComponentMounted(boardMemberId);
    }, []);

    function handleChange(isValid, formName, form) {
        if (isValid) {
            setValidForms(add(validForms, formName));
        } else {
            setValidForms(remove(validForms, formName, (a, b) => a === b));
        }

        setApplicationData({
            ...applicationData,
            ...form,
        });
    }
    const handleClick = () => {
        dispatch(
            smeDocumentActions.smeFetchDocumentTrigger({
                token,
                gwUrl,
                mockApiCalls,
                accountId: activeAccountId,
                documentId: defaultPromissoryNoteId,
            })
        );
    };

    if (!authenticated) {
        return <Redirect to={E_Routes.ROOT} />;
    }

    if (accountState === E_AllowedAccountStates.COLLECTION) {
        return (
            <StyledGrid styleConfig={{ root: FrontPageStyles.accountCollectionMainContainer() }}>
                <CollectionBlock
                    collectionStateHeading={fm(messages.collectionStateHeading)}
                    collectionStateText={fm(messages.collectionStateText)}
                    collectionStatePhoneText={fm(messages.collectionStatePhoneText)}
                    collectionStatePhoneNumber={fm(messages.collectionStatePhoneNumber)}
                    collectionStateEmailText={fm(messages.collectionStateEmailText)}
                    collectionStateEmailLinkLink={fm(messages.collectionStateEmailLinkLink)}
                    collectionStateEmailLinkText={fm(messages.collectionStateEmailLinkText)}
                    defaultPromissoryNoteId={defaultPromissoryNoteId}
                    handleClick={handleClick}
                    documentInUse={documentInUse}
                />
            </StyledGrid>
        );
    }

    const availableCreditLimit = account?.availableCreditLimit;

    useEffect(() => {
        if (component && component === "withdraw") {
            window.scrollTo({ top: 500, behavior: "smooth" });
        }
    });

    return (
        <StyledGrid styleConfig={{ root: FrontPageStyles.frontPageRootStyles() }}>
            <StyledPageTitle
                title={fm(messages.frontPageTitle)}
                styleConfig={{
                    pageTitleContainer: props.styleConfig.titleBox,
                    pageTitleText: props.styleConfig.pageTitle,
                }}
            />
            <Scroll to="withdraw-section">
                <StyledGrid styleConfig={{ root: props.styleConfig.nostoContainer }}>
                    <Image
                        imageAlt="currency sign"
                        imageSrc={currencyImage}
                        styleConfig={{ root: props.styleConfig.nostoImage }}></Image>
                    <Font styleConfig={{ root: props.styleConfig.nostoText }}>
                        {fm(messages.withdrawButtonText)}
                    </Font>
                </StyledGrid>
            </Scroll>
            <StyledGrid styleConfig={{ root: props.styleConfig.mainContentContainer }}>
                <AccountDetailBlock />
                <NewsBlock />
                <WithdrawBlock
                    availableCreditLimit={availableCreditLimit}
                    overdueDays={overdueDays}
                    unpaidAmount={unpaidAmount}
                    accountState={account?.state}
                    blockedStatus={account?.blockedStatus}
                    handleChange={handleChange}
                />
            </StyledGrid>
        </StyledGrid>
    );
}
