import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pageTitle: {
        id: "flex-online-se-user-page-title",
    },
    Subheading2: {
        id: "flex-online-se-topup-page-subheading-2",
    },
    Subheading3: {
        id: "flex-online-se-topup-page-subheading-3",
    },
    companyName: {
        id: "flex-online-se-topup-page-text-company-name",
    },
    businessID: {
        id: "flex-online-se-topup-page-text-company-businessID",
    },
    guaranteeName: {
        id: "flex-online-se-topup-page-text-guarantee-name",
    },
    guaranteeSSN: {
        id: "flex-online-se-topup-page-text-guarantee-SSN",
    },
    guaranteePhone: {
        id: "flex-online-se-topup-page-text-guarantee-phone",
    },
    TopInfo: {
        id: "flex-online-se-user-page-top-info",
    },
    textCustomerNumber: {
        id: "flex-online-se-user-page-text-customer-number",
    },
    textSSN: {
        id: "flex-online-se-user-page-text-SSN",
    },
    textEmail: {
        id: "flex-online-se-user-page-text-email",
    },
    textAddress: {
        id: "flex-online-se-user-page-text-address",
    },
    alertTextRequired: {
        id: "flex-online-se-user-page-required-error-message",
    },
    companyInfoTitle: {
        id: "flex-online-se-user-page-company-info-box-title",
    },
    wrongEmailErrorMessage: {
        id: "flex-online-se-user-page-error-wrong-email",
    },
    wrongPhoneErrorMessage: {
        id: "flex-online-se-user-page-error-wrong-phone",
    },
    editBtnText: {
        id: "flex-online-se-user-page-edit-button-text",
    },
    saveBtnText: {
        id: "flex-online-se-user-page-save-button-text",
    },
});
