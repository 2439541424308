import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pageTitle: {
        id: "flex-online-se-topup-page-title",
    },
    alertTextRequired: {
        id: "flex-online-se-topup-text-alert-required-field",
    },
    dropdownChoose: {
        id: "flex-online-se-topup-page-dropdown-menu-choose",
    },
    heading: {
        id: "flex-online-se-topup-page-heading",
    },
    Subheading1: {
        id: "flex-online-se-topup-page-subheading-1",
    },
    Subheading2: {
        id: "flex-online-se-topup-page-subheading-2",
    },
    Subheading3: {
        id: "flex-online-se-topup-page-subheading-3",
    },
    topTextInfoLongText: {
        id: "flex-online-se-topup-page-long-info",
    },
    topTextInfo2: {
        id: "flex-online-se-topup-page-top-info-2",
    },
    textCreditLimit: {
        id: "flex-online-se-topup-page-text-credit-limit",
    },
    textNewCredit: {
        id: "flex-online-se-topup-page-text-new-credit-limit",
    },
    textLoanPurpose: {
        id: "flex-online-se-topup-page-text-loan-purpose",
    },
    textLoanPurposeDescription: {
        id: "flex-online-se-topup-page-text-loan-purpose-description",
    },
    textLoanPurposeDescriptionShort: {
        id: "flex-online-se-topup-page-text-loan-purpose-description-short",
    },
    buyEquipment: {
        id: "flex-online-se-topup-page-buy-equipment",
    },
    hireStaff: {
        id: "flex-online-se-topup-page-hire-staff",
    },
    financeDebt: {
        id: "flex-online-se-topup-page-finance-debt",
    },
    marketing: {
        id: "flex-online-se-topup-page-marketing",
    },
    buyInventory: {
        id: "flex-online-se-topup-page-buy-inventory",
    },
    unexpected: {
        id: "flex-online-se-topup-page-unexpected",
    },
    expansion: {
        id: "flex-online-se-topup-page-expansion",
    },
    other: {
        id: "flex-online-se-topup-page-other",
    },
    companyName: {
        id: "flex-online-se-topup-page-text-company-name",
    },
    businessID: {
        id: "flex-online-se-topup-page-text-company-businessID",
    },
    monhtlySale: {
        id: "flex-online-se-topup-page-text-monthly-sales",
    },
    emailText: {
        id: "flex-online-se-topup-page-text-email",
    },
    placeholder: {
        id: "flex-online-se-topup-page-placeholder-text",
    },
    guaranteeName: {
        id: "flex-online-se-topup-page-text-guarantee-name",
    },
    guaranteeSSN: {
        id: "flex-online-se-topup-page-text-guarantee-SSN",
    },
    guaranteePhone: {
        id: "flex-online-se-topup-page-text-guarantee-phone",
    },
    campaignCode: {
        id: "flex-online-se-topup-page-campaign-code",
    },
    checkboxInfo1: {
        id: "flex-online-se-topup-page-checkbox-info-1",
    },
    checkboxInfo2: {
        id: "flex-online-se-topup-page-checkbox-info-2",
    },
    checkboxInfo2Modal: {
        id: "flex-online-se-topup-page-checkbox-info-2-modal",
    },
    checkboxInfo2Link: {
        id: "flex-online-se-topup-page-checkbox-info-2-link",
    },
    checkboxInfo2LinkText: {
        id: "flex-online-se-topup-page-checkbox-info-2-text",
    },
    buttontextPreview: {
        id: "flex-online-se-topup-page-button-text-preview",
    },
    buttonTextSend: {
        id: "flex-online-se-topup-page-button-text-send",
    },
    buttonTextGoback: {
        id: "flex-online-se-topup-page-button-text-goBack",
    },
    alertText: {
        id: "flex-online-se-topup-page-alert-text",
    },
    sentApplicationInfo1: {
        id: "flex-online-se-topup-page-sent-application-info-1",
    },
    sentApplicationInfo2: {
        id: "flex-online-se-topup-page-sent-application-info-2",
    },
    sentApplicationInfo3: {
        id: "flex-online-se-topup-page-sent-application-info-3",
    },
    customerServiceLink: {
        id: "flex-online-se-email-customerService-link",
    },
    customerServiceLinkText: {
        id: "flex-online-se-email-customerService-text",
    },
    sentModalTitle: {
        id: "flex-online-se-topup-page-sent-application-modal-title",
    },
    sentModalInfo1: {
        id: "flex-online-se-topup-page-sent-application-modal-info1",
    },
    sentModalInfo2: {
        id: "flex-online-se-topup-page-sent-application-modal-info2",
    },
    sentModalInfo3: {
        id: "flex-online-se-topup-page-sent-application-modal-info3",
    },
    errorSomeDataMissing: {
        id: "flex-online-se-topup-page-error-contact-customer-service",
    },
    errorUpdateYourData: {
        id: "flex-online-se-topup-page-error-update-contact-info",
    },
    errorAllDataMissing: {
        id: "flex-online-se-topup-page-error-missing-all-data",
    },
});
