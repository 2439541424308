import { defineMessages } from "react-intl";

export const messages = defineMessages({
    frontPageTitle: {
        id: "flex-online-se-front-page-title",
    },
    frontPageHeadingSummary: {
        id: "flex-online-se-front-page-heading-summary",
    },
    frontPageSummaryAvailableText: {
        id: "flex-online-se-front-page-summary-available-text",
    },
    frontPageSummaryWithdrawnText: {
        id: "flex-online-se-front-page-summary-withdrawn-text",
    },
    frontPageSummaryCreditlimitText: {
        id: "flex-online-se-front-page-summary-creditlimit-text",
    },
    frontPageSummaryInfo: {
        id: "flex-online-se-frontpage-summary-info",
    },
    recentNewsTitle: {
        id: "flex-online-se-frontpage-recent-news-heading",
    },
    recentNewsReadMore: {
        id: "flex-online-se-frontpage-recent-news-read-more",
    },
    withdrawTitle: {
        id: "flex-online-se-front-page-withdraw-heading",
    },
    withdrawInputLabel: {
        id: "flex-online-se-front-page-withdraw-input-label",
    },
    withdrawInputplaceholder: {
        id: "flex-online-se-front-page-withdraw-input-placeholder",
    },
    withdrawButtontext: {
        id: "flex-online-se-font-page-withdraw-button-text",
    },
    withdrawButtonInfoText: {
        id: "flex-online-se-font-page-withdraw-button-info",
    },
    withdrawInfo1: {
        id: "flex-online-se-front-page-withdraw-side-info-1",
    },
    withdrawInfo2: {
        id: "flex-online-se-front-page-withdraw-side-info-2",
    },
    withdrawInfo3: {
        id: "flex-online-se-front-page-withdraw-side-info-3",
    },
    withdrawInfo4: {
        id: "flex-online-se-front-page-withdraw-side-info-4",
    },
    withdrawSentMessage: {
        id: "flex-online-se-online-front-page-withdraw-text-sent",
    },
    withdrawAlertText1: {
        id: "flex-online-se-front-page-withdraw-alert-text-too-big",
    },
    withdrawAlertText2: {
        id: "flex-online-se-front-page-withdraw-alert-text-too-small",
    },
    withdrawOverdueInvoice: {
        id: "flex-online-se-front-page-withdraw-overdue-invoice",
    },
    withdrawAvailableCreditTooSmall: {
        id: "flex-online-se-front-page-withdraw-available-credit-too-small",
    },
    withdrawNotAbleToMakeWithdrawal: {
        id: "flex-online-se-front-page-withdraw-not-able-to-make-withdrawal",
    },
    withdrawNoIbanNumber: {
        id: "flex-online-se-front-page-withdraw-no-iban-number",
    },
    euroCurrency: {
        id: "flex-online-se-euro-currency",
    },
    notEligibleText: {
        id: "flex-online-se-not-eligible-text",
    },
    withdrawButtonText: {
        id: "flex-online-se-withdraw-button-text",
    },
    collectionStateHeading: {
        id: "flex-online-se-collection-state-heading",
    },
    collectionStateText: {
        id: "flex-online-se-collection-state-text",
    },
    collectionStatePhoneText: {
        id: "flex-online-se-collection-state-phone-text",
    },
    collectionStatePhoneNumber: {
        id: "flex-online-se-collection-state-phone-number",
    },
    collectionStateEmailText: {
        id: "flex-online-se-collection-state-email-text",
    },
    collectionStateEmailLinkLink: {
        id: "flex-online-se-collection-state-email-link-link",
    },
    collectionStateEmailLinkText: {
        id: "flex-online-se-collection-state-email-link-text",
    },
    loanDocumentsButtonText: {
        id: "flex-online-se-loan-page-button-text-loan-documents",
    },
});
