import { defineMessages } from "react-intl";

export const messages = defineMessages({
    frontPage: {
        id: "flex-online-se-header-frontpage",
    },
    loanPage: {
        id: "flex-online-se-header-loanInfo",
    },
    topUpPage: {
        id: "flex-online-se-header-TopUp",
    },
    userInfo: {
        id: "flex-online-se-header-userInfo",
    },
    contactInfo: {
        id: "flex-online-se-header-contactInfo",
    },
    logout: {
        id: "flex-online-se-header-button-logout",
    },
    logIn: {
        id: "flex-online-se-header-button-login",
    },
    mobileTopUp: {
        id: "flex-online-se-header-mobile-topUp",
    },
    mobileMore: {
        id: "flex-online-se-header-mobile-more",
    },
    mobileLoanInfo: {
        id: "flex-online-se-header-mobile-loanInfo",
    },
    mobileUserInfo: {
        id: "flex-online-se-header-mobile-user-information",
    },
    mobileCustomerService: {
        id: "flex-online-se-header-mobile-customer-service",
    },
});
