import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pageTitle: {
        id: "flex-online-se-noloan-page-title",
    },
    heading: {
        id: "flex-online-se-noloan-page-heading",
    },
    linkText: {
        id: "flex-online-se-noloan-page-link-text",
    },
    url: {
        id: "flex-online-se-noloan-page-title-link",
    },
    bodyText: {
        id: "flex-online-se-noloan-page-body-text",
    },
});
