import React, { useState } from "react";

import { Font } from "@opr-finance/component-fonts";
import { StyledGrid } from "@opr-finance/component-grid";
import { Link } from "@opr-finance/component-link-to";
import { ButtonStyles, FontsStyles } from "@opr-finance/theme-flex-online";
import { StyledButton } from "@opr-finance/component-button";
import { Icon } from "@opr-finance/component-icon";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { T_CollectionBlockProps } from "./types";
import { FrontPageStyles } from "@opr-finance/theme-flex-online";
import { DocumentModal } from "../DocumentModal";
import { messages } from "../../pages/LoanPage/messages";
import { useIntl } from "react-intl";
import { marginTop } from "styled-system";

export function CollectionBlock(props: T_CollectionBlockProps) {
    const logger = new ConsoleLogger({ level: LOG_LEVEL });
    const { formatMessage: fm } = useIntl();
    const [modalOpen, toggleModalOpen] = useState(false);

    logger.log("test info--", props.defaultPromissoryNoteId);

    return (
        <StyledGrid styleConfig={{ root: FrontPageStyles.frontPageRootStyles() }}>
            <StyledGrid styleConfig={{ root: FrontPageStyles.accountCollectionInfoBox() }}>
                <Font styleConfig={{ root: FrontPageStyles.accountCollectionInfoHeading() }}>
                    {props.collectionStateHeading}
                </Font>
                <Font styleConfig={{ root: FrontPageStyles.accountCollectionInfoText() }}>
                    {props.collectionStateText}
                </Font>
                <StyledGrid styleConfig={{ root: FrontPageStyles.collectionInfoItemContainer() }}>
                    <Font styleConfig={{ root: FrontPageStyles.accountCollectionInfoText() }}>
                        {props.collectionStatePhoneText}
                    </Font>
                    <Font styleConfig={{ root: FrontPageStyles.accountCollectionInfoText() }}>
                        {` ${props.collectionStatePhoneNumber}`}
                    </Font>
                </StyledGrid>
                <StyledGrid styleConfig={{ root: FrontPageStyles.collectionInfoItemContainer() }}>
                    <Font styleConfig={{ root: FrontPageStyles.accountCollectionInfoText() }}>
                        {`${props.collectionStateEmailText} `}
                    </Font>
                    <Link
                        styleConfig={{ root: FrontPageStyles.accountCollectionEmailLink() }}
                        href={props.collectionStateEmailLinkLink}>
                        {props.collectionStateEmailLinkText}
                    </Link>
                </StyledGrid>
            </StyledGrid>
            <StyledGrid styleConfig={{ root: FrontPageStyles.creditAgreementContainer() }}>
                <Font styleConfig={{ root: FrontPageStyles.creditAgreementText() }}>
                    {fm(messages.pageTitle)}
                </Font>
                <StyledGrid styleConfig={{ root: FrontPageStyles.downloadButtonContainer() }}>
                    {props.documentInUse ? (
                        props.defaultPromissoryNoteId ? (
                            <StyledButton
                                onClick={() => toggleModalOpen(!modalOpen)}
                                styleConfig={{
                                    root: ButtonStyles.greenButtonStyles({ marginTop: "10px" }),
                                }}>
                                <Font styleConfig={{ root: ButtonStyles.buttonFontStyles() }}>
                                    {fm(messages.loanDocumentsButtonText)}
                                    <Icon icon={["fa", "angle-double-right"]} />{" "}
                                </Font>
                            </StyledButton>
                        ) : (
                            <Font styleConfig={{ root: FontsStyles.fontContentText(true) }} as="p">
                                {fm(messages.documentsError)}
                            </Font>
                        )
                    ) : (
                        <p></p>
                    )}
                </StyledGrid>

                <DocumentModal
                    toggleModalOpen={toggleModalOpen}
                    modalOpen={modalOpen}
                    handleClick={props.handleClick}
                />
            </StyledGrid>
        </StyledGrid>
    );
}
