import { defineMessages } from "react-intl";

export const messages = defineMessages({
    expiredPageTitle: {
        id: "flex-online-se-expired-page-title",
    },
    expiredPageHeadingBox: {
        id: "flex-online-se-expired-page-heading-box",
    },
    expiredPageText: {
        id: "flex-oline-se-expired-page-text",
    },
    expiredPageLinkText: {
        id: "flex-online-se-expired-page-link-frontpage-text",
    },
    expiredPageLinkLink: {
        id: "flex-online-se-expired-page-link-frontpage-link",
    },
});
